import React from "react";

function NotificationPicture(){
    return (
        <div>
             <img className="notification-picture" src="../../../images/image-chess.webp" alt="chess_img" />
        </div>
    )
}

export default NotificationPicture;