const dataList = [
    {
        name:"Mark Webber ",
        text:" react to your recent post",
        notificationObject:"My first tournament today!",
        time:"1 m ago"
    },
    {
        name:"Angela Gray",
        text:"follow you",
        time:"5 m ago"
    },
    {
        name:"Jacob Thompson",
        text:"has joined your group",
        notificationObject:"Chess Club",
        time:"1 day ago"
    },
    {
        name:"Rizky Hasanuddin",
        text:"send you a private message",
        time:"5 days ago"
    },
    {
        name:"Kimberly Smith",
        text:"commend on your picture",
        time:"1 week ago"
    },
    {
        name:"Nathan Peterson",
        text:"react to your recent post",
        notificationObject:"5 End-game strategies to increase your win rate",
        time:"2 weeks ago"
    },
    {
        name:"Anna Kim",
        text:"left the group",
        notificationObject:"Chess Club",
        time:"2 weeks ago"
    }

];

export default dataList;